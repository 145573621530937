<template>
  <TepmplateBlock modClass>
    <div class="row registration-wrap">
      <FormStandart
        :registration="registration"
        title="Регистрация"
        subtitle="Войти"
        link="/auth"
        secondLabel="E-mail"
        btn-text="Подтвердить"
        @success="onRegisterSuccess"
        @loadingChange="doChangeLoading"
        :is-show-popover="true"
        :needSecondValidation="true"
        :isIntroPassed="isIntroPassed === 'true'"
        :loading="isDataSubmitting"
      >
        <template #title>
          <span style="font-size: 12px">
            <HelpPopover
              message="При регистрации не обязательно сразу правильно заводить данные(исключение - электронная почта).
               Наш менеджер после регистрации свяжется с Вами и поможет завести все корректно, а так же проведет мини
               обучение по использованию приложения."
            />
          </span>
        </template>
      </FormStandart>
    </div>
    <b-modal centered id="my-modal" hide-footer title="">
      <div class="d-block text-center">
        <h5>
          Вы зарегистрированы. На ваш адрес отправлены логин и пароль. Теперь
          заходите в систему
        </h5>
      </div>
    </b-modal>

    <div
      v-if="isIntroPassed === 'false' || !isIntroPassed"
      class="dark-intro-background"
    >
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip p-2 w-25 text-center">
          Введите ваш email адрес, ИНН компании и нажмите кнопку "Подтвердить"
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import FormStandart from "../../components/Form/FormStandart";
import TepmplateBlock from "../../components/TepmplateBlock";
import { mapGetters } from "vuex";
import HelpPopover from "../../components/HelpPopover";
import { doPoseTip } from "@/utils";

export default {
  name: "Registration",
  components: { TepmplateBlock, FormStandart, HelpPopover },
  computed: {
    ...mapGetters(["getErrors"])
  },
  data() {
    return {
      registration: false,
      success: false,
      isIntroPassed: undefined,
      isDataSubmitting: false
    };
  },
  methods: {
    onRegisterSuccess() {
      localStorage.setItem("isAdminProfileFilled", "false");
      localStorage.setItem("isGeneralInfoExist", "false");
      localStorage.setItem("isIntroPassed", "false");
      localStorage.setItem("isOfficeAdded", "false");
      localStorage.setItem("isProfileFilled", "false");
      this.$bvModal.show("my-modal");
      setTimeout(() => {
        this.$bvModal.hide("my-modal");
        this.$router.push({ name: "Auth" });
      }, 3000);
    },
    doChangeLoading(newValue) {
      this.isDataSubmitting = newValue;
    }
  },
  created() {
    this.registration = true;
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
  },
  mounted() {
    if (this.isIntroPassed === "false") {
      doPoseTip(".card-title", "top");
    }
  }
};
</script>

<style lang="scss" scoped>
.registration-wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
